import '../Navbar/Navbar.css';
import React from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo"></div>
      <div className="desktopMenu">
        <Link
          to="hero"
          smooth={true}
          duration={500}
          className='desktopMenuListItem'
        >
          Home
        </Link>
        <Link
          to="about"
          smooth={true}
          duration={500}
          className='desktopMenuListItem'
        >
          About
        </Link>
        <Link
          to="projects"
          smooth={true}
          duration={500}
          className='desktopMenuListItem'
        >
          Projects
        </Link>
        <Link
          to="contact"
          smooth={true}
          duration={500}
          className='desktopMenuListItem'
        >
          Contact
        </Link>
      </div>
     
    </nav>
  );
}

export default Navbar;
