import React from 'react';
import './About.css';
// import profile from '../../images/profile.png'

const About = () => {
  return (
    <section id="about" className="about" data-aos="flip-left">
      <div className="aboutContent" data-aos="flip-down">
        <h2>About Me</h2>
        <div className="aboutDetails">
          {/* <img src={profile} alt="Profile" className="profilePic" /> */}
          <p>
            Hello! I'm Maria, a passionate web developer eager to create innovative and user-friendly web applications. 
            Currently a 4th-semester student, I am constantly learning and improving my skills. My journey in web development 
            is driven by curiosity and a love for coding. I enjoy solving complex problems and turning ideas into reality.
            When I'm not coding, you can find me exploring the latest tech trends, hiking, or enjoying a good book. Let's build something amazing together!
          </p>
        </div>
        <div className="socialLinks">
          <a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://github.com/yourprofile" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
