import React from 'react';
import './Skills.css';
import UIDesign from '../../images/web-design.png'; 
import FigmaIcon from '../../images/icons8-figma-480.png';
import AdobeIcon from '../../images/adobe.png';
import ReactIcon from '../../images/icons8-react-native-480.png';
import HTMLIcon from '../../images/html.png';
import CSSIcon from '../../images/css.png';
import JSIcon from '../../images/js.png';
import CSharpIcon from '../../images/icons8-c-480.png';
import SQLIcon from '../../images/database.png';
import ResearchIcon from '../../images/research.png';

const Skills = () => {
  return (
    <section id="skills">
      <span className="skillTitle">Skills</span>
      <span className="skillDescription">
        I have a diverse skill set that enables me to design and develop modern, user-friendly web applications. 
      </span>
      <div className="skillbars">
        <div className="skillBar" data-aos="fade-right">
          <img src={UIDesign} alt="UI/UX Design" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>UI/UX Design</h2>
            <p>
              Crafting intuitive and visually appealing user interfaces, focusing on user-centric design principles to enhance user experience and engagement.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-left">
          <img src={FigmaIcon} alt="Figma" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>Figma</h2>
            <p>
              Creating wireframes, prototypes, and high-fidelity designs using Figma, ensuring a smooth and efficient design process.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-right">
          <img src={AdobeIcon} alt="Adobe" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>Adobe Creative Suite</h2>
            <p>
              Utilizing Adobe XD, Photoshop, and Illustrator for designing user interfaces, graphics, and visual elements.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-left">
          <img src={ReactIcon} alt="React" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>React</h2>
            <p>
              Developing dynamic and responsive web applications using React, focusing on component-based architecture and state management.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-right">
          <img src={HTMLIcon} alt="HTML" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>HTML</h2>
            <p>
              Structuring web content with semantic HTML, ensuring accessibility and SEO best practices.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-left">
          <img src={CSSIcon} alt="CSS" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>CSS & Tailwind CSS</h2>
            <p>
              Styling websites using CSS and Tailwind CSS, creating responsive and visually appealing layouts.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-right">
          <img src={JSIcon} alt="JavaScript" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>JavaScript</h2>
            <p>
              Implementing dynamic and interactive features using modern JavaScript (ES6+).
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-left">
          <img src={CSharpIcon} alt="C#" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>C#</h2>
            <p>
              Basic understanding of C# for developing desktop and web applications.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-right">
          <img src={SQLIcon} alt="SQL" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>SQL</h2>
            <p>
              Managing and querying databases using SQL for efficient data handling and retrieval.
            </p>
          </div>
        </div>
        <div className="skillBar" data-aos="fade-left">
          <img src={ResearchIcon} alt="Research" className="skillBarImg" />
          <div className='skillBartext'>
            <h2>Research</h2>
            <p>
              Conducting thorough research to stay updated with the latest trends and technologies in web development.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
