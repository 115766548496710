import React from 'react';
import './Contact.css';


const Contact = () => {
  return (
    <section id="contactPage">
      <div id="contact">
        <h2 className="contactPageTitle">Contact</h2>
        <form action="https://formspree.io/f/xbjnnlzo" method="POST" className="contactForm">
          <input type="text" name="name" className="name" placeholder="Your Name" required />
          <input type="email" name="email" className="email" placeholder="Your Email" required />
          <textarea name="message" className="msg" placeholder="Your Message" rows="9" required></textarea>
          <button type="submit" className="submitBtn">Submit</button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
