import React from 'react';
import './Projects.css'; // Updated path to match the directory structure
import Project from '../../images/projects.jpeg';

const Projects = () => {
  return (
    <section id="projects">
      <h2 className="worksTitle">Projects</h2>
      <p className="worksDescription">
        Here are some of the projects I've worked on. Each project showcases different skills and techniques I've utilized in web development.
      </p>
      <div className="projectImgs">
        <img src={Project} alt="Project 1" className="projectImg" />        
        <img src={Project} alt="Project 2" className="projectImg" />        
        <img src={Project} alt="Project 3" className="projectImg" />        
        <img src={Project} alt="Project 4" className="projectImg" />        
        <img src={Project} alt="Project 5" className="projectImg" />        
        <img src={Project} alt="Project 6" className="projectImg" />        
      </div>
    </section>
  );
}

export default Projects;
