import './App.css';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Projects from './components/Projects/Projects'
import Contact from './components/Contact/Contact';
import Skills from './components/Skills/Skills'
import AOS from 'aos';
import 'aos/dist/aos.css';
import React,{useEffect} from 'react';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
    console.log('AOS initialized'); // Check if this logs to the console
  }, []);

  return (
    <>
      <Navbar/>
      <Hero/>
      <About/>
      <Skills/>
      <Projects/>
      <Contact/>
    </>
  );
}

export default App;
