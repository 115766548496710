import '../Hero/Hero.css'
import React from 'react'


const Hero = () => {
  return (
    <section id='hero' >
      <div className="introContent" >
        <span className="hello">Hello</span>
        <span className='introText'>I'm <span className='introName'>Maria Mihaylova</span> <br /> Web Developer</span>
        <p className="introPara">A student developer, passionate about building user-friendly and innovative web applications.</p>
      </div>
      {/* <img src="your-image-path.jpg" alt="Background" className='bg'/> */}
    </section>
  )
}

export default Hero